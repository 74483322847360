import { PaymentType } from '../../../../utils/mappers/service.mapper';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type setSelectedPaymentType = (selectedPaymentType: PaymentType) => void;

export function createSetSelectedPaymentTypeAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): setSelectedPaymentType {
  return async (selectedPaymentType: PaymentType) => {
    const [, setState] = getControllerState();

    setState({ selectedPaymentType });
  };
}
